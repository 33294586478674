import React from 'react';
import { FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  VKShareButton } from 'react-share';
import Icon from './Icon';
import config from '../../data/config';
import PropTypes from '../PropTypes';

const propTypes = {
  post: PropTypes.object.isRequired
};

const logClick = type => () => {
  window.ga && window.ga('send', 'event', {
    eventCategory: 'Share',
    eventAction: 'Success',
    eventLabel: type
  });
};

function SocialLinks({ post }) {
  const url = config.siteUrl + config.pathPrefix + post.slug;

  return (
    <div className="socials">
      <VKShareButton
        className="button element socials_item socials_item__vk"
        url={url}
        title={post.title}
        image={post.cover}
        description={post.description}
        beforeOnClick={logClick('vk')}
        resetButtonStyle={false}
      >
        <Icon icon="vk" color="white" />
      </VKShareButton>
      <FacebookShareButton
        className="button element socials_item socials_item__fb"
        url={url}
        quote={post.title}
        beforeOnClick={logClick('fb')}
        resetButtonStyle={false}
      >
        <Icon icon="fb" color="white" />
      </FacebookShareButton>
      <TelegramShareButton
        className="button element socials_item socials_item__telegram"
        url={url}
        title={post.title}
        beforeOnClick={logClick('telegram')}
        resetButtonStyle={false}
      >
        <Icon icon="telegram" color="white" />
      </TelegramShareButton>
      <TwitterShareButton
        className="element socials_item socials_item__twitter button"
        url={url}
        title={post.title}
        beforeOnClick={logClick('twitter')}
        resetButtonStyle={false}
      >
        <Icon icon="twitter" color="white" />
      </TwitterShareButton>
    </div>
  );
}

SocialLinks.propTypes = propTypes;

export default SocialLinks;
